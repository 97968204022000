import React, { useState, useRef, useEffect } from "react";
import { Button, Container, Alert,Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner, } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./RegistrationForm.css";
import eventImage from "./images/eventImage.png"; // Adjust the path as necessary
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const CancelPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [date, setDate] = React.useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [dupCancelModalOpen, setDupCancelModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUpdate = async () => {
    setLoading(true);
    const params = new URLSearchParams(location.search);
    const uuid = params.get("p1");

    if (!uuid || uuid.length !== 8) {
      // Handle the case where uuid is not present
      console.error("UUID is missing");
      setError("UUID is missing or invalid.");
      return;
    }

    try {
      const response = await axios.put(`${apiUrl}/eventCancel`, {
        uuid,
      },{
        headers: {
          "Content-Type": "application/json",
        },
      crossdomain: true
    });
      // console.log(response.data.message);

      if (response.status === 200) {
        if (response.data.message === "Not found.") {
          setDupCancelModalOpen(true);
        }else{
          // navigate(`/HPPPlandeParis/registration`);
          navigate(`/HPPPlandeParis/cancelComplete`);
        }
      }
    } catch (error) {
      setErrorModalOpen(true); // Open the error modal
      console.error("API error:", error); // Optional: Log the error for debugging
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleErrorClose = () => {
    setErrorModalOpen(false); // Close the modal
  };

  const handleDupCancelClose = () => {
    setDupCancelModalOpen(false);

    navigate(`/HPPPlandeParis/registration`);
  };

  useEffect(() => {

    const fetchData = async () => {
      const params = new URLSearchParams(location.search);
      const uuid = params.get("p1");
      try {
        const res = await axios.post(`${apiUrl}/getDateByUUID`, { uuid },{
          headers: {
            "Content-Type": "application/json",
          },
          crossdomain: true,
        });
        // console.log(res.data.oabDate); // Handle the response from the backend
        setDate(res.data.oabDate);
      } catch (error) {
        console.error("API error:", error); // Optional: Log the error for debugging
      }
    };

    // Call the function to fetch data
    fetchData();
  }, []);

  return (
    <div>
      <div className="text-center">
        <img
          src={eventImage}
          alt="Event"
          // className="img-fluid mb-4"
          style={{ width: "100%", height: "auto", maxWidth: "700px" }}
        />
      </div>
    <Container className="mt-5" style={{ textAlign: "center" }}>
      กรุณา ยืนยันการยกเลิกเข้าร่วมกิจกรรม
      <br />
      Plan de Paris Event ที่ Hall of Fame, Siam Paragon
      <br />
      วันที่ {date}
      <br />
      <br />
      { loading ? <Spinner color="secondary" /> :
      <Button color="primary" onClick={handleUpdate}
      style={{
        width: "100%",
        maxWidth: "200px",

        backgroundColor: "#000",
        borderColor: "#000",
        color: "#FFF",
        borderRadius: "1px"
      }}
      >
        Confirm
      </Button> }

      <Modal isOpen={errorModalOpen} toggle={handleErrorClose}>
        <ModalHeader toggle={handleErrorClose}>
          มีปัญหาในการเชื่อมต่อ
        </ModalHeader>
        <ModalBody>
          ระบบมีปัญหาในการส่งข้อมูล กรุณาลองอีกครั้งในภายหลัง
          <br />
          There was an issue submitting your data. Please try again later.
        </ModalBody>
        <ModalFooter>
          <Button style={{
          backgroundColor: "#000",
          borderColor: "#000",
          color: "#FFF",
        borderRadius: "1px"
      }} onClick={handleErrorClose}>
            ตกลง
          </Button>
        </ModalFooter>
      </Modal>

      {/* Dup Modal */}
      <Modal isOpen={dupCancelModalOpen} toggle={handleDupCancelClose}>
        <ModalHeader toggle={handleDupCancelClose}>แจ้งเตือน</ModalHeader>
        <ModalBody>
          หมายเลขโทรศัพท์หรืออีเมลถูกยกเลิกลงทะเบียนไปแล้ว
          <br />
        </ModalBody>
        <ModalFooter>
          <Button style={{
          backgroundColor: "#000",
          borderColor: "#000",
          color: "#FFF",
        borderRadius: "1px"
      }} onClick={handleDupCancelClose}>
            ตกลง
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
    </div>
  );
};

export default CancelPage;
