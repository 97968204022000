// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import RegistrationForm from "./RegistrationForm";
// import ConfirmPage from './ConfirmPage';
// import CancelPage from './CancelPage';
// import CancelCompletePage from './CancelCompletePage';
// import DashboardPage from './DashboardPage';
// import DashboardDetailPage from './DashboardDetailPage';
// import LoginPage from './LoginPage';
// import RegisterPage from './RegisterPage';

// const App = () => {
//     const [isAuthenticated, setIsAuthenticated] = useState(() => {
//         return localStorage.getItem('isAuthenticated') === 'true';
//     });

//     useEffect(() => {
//         if (!isAuthenticated) {
//             localStorage.removeItem('isAuthenticated');
//         }
//     }, [isAuthenticated]);

//     const handleLogout = () => {
//         setIsAuthenticated(false);
//         localStorage.removeItem('isAuthenticated');
//     };

//     return (
//         <Router>
//             <div>
//                 <Routes>
//                     {/* Public Routes */}
//                     <Route path="HPPPlandeParis" element={<RegistrationForm />} />
//                     <Route path="HPPPlandeParis/registration" element={<RegistrationForm />} />
//                     <Route path="HPPPlandeParis/confirmation" element={<ConfirmPage />} />
//                     <Route path="HPPPlandeParis/cancellation" element={<CancelPage />} />
//                     <Route path="HPPPlandeParis/cancelComplete" element={<CancelCompletePage />} />

//                     <Route 
//                         path="HPPPlandeParis/registerPage" 
//                         element={localStorage.getItem('isAuthenticated') ? <RegisterPage /> : <Navigate to="/login" />} 
//                     />
//                     {/* Protected Routes */}
//                     <Route path="HPPPlandeParis/dashboard" 
//                         element={localStorage.getItem('isAuthenticated') ? <DashboardPage handleLogout={handleLogout} /> : <LoginPage />} 
//                     />
//                     <Route 
//                         path="HPPPlandeParis/dashboardDetail/:campaign_id/:campaign_name" 
//                         element={localStorage.getItem('isAuthenticated') ? <DashboardDetailPage /> : <Navigate to="/login" />} 
//                     />

//                     {/* Login Route */}
//                     <Route 
//                         path="/login" 
//                         element={
//                           localStorage.getItem('isAuthenticated') ? <Navigate to="HPPPlandeParis/dashboard" /> : <LoginPage />
//                         } 
//                     />
                    
//                     {/* Redirect unknown routes to login */}
//                     {/* <Route path="*" element={<Navigate to="/login" />} /> */}
//                 </Routes>
//             </div>
//         </Router>
//     );
// };

// export default App;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import RegistrationForm from "./RegistrationForm";
import ConfirmPage from './ConfirmPage';
import CancelPage from './CancelPage';
import CancelCompletePage from './CancelCompletePage';
import DashboardPage from './DashboardPage';
import DashboardDetailPage from './DashboardDetailPage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        return localStorage.getItem('isAuthenticated') === 'true';
    });

    useEffect(() => {
        if (!isAuthenticated) {
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('token'); // ลบ JWT token ออกจาก localStorage
        }
    }, [isAuthenticated]);

    const handleLogout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('token'); // ลบ JWT token ออกจาก localStorage
    };

    return (
        <Router>
            <div>
                <Routes>
                    {/* Public Routes */}
                    <Route path="HPPPlandeParis" element={<RegistrationForm />} />
                    <Route path="HPPPlandeParis/registration" element={<RegistrationForm />} />
                    <Route path="HPPPlandeParis/confirmation" element={<ConfirmPage />} />
                    <Route path="HPPPlandeParis/cancellation" element={<CancelPage />} />
                    <Route path="HPPPlandeParis/cancelComplete" element={<CancelCompletePage />} />

                    <Route 
                        path="HPPPlandeParis/registerPage" 
                        element={isAuthenticated ? <RegisterPage /> : <Navigate to="/login" />} 
                    />
                    {/* Protected Routes */}
                    <Route path="HPPPlandeParis/dashboard" 
                        element={isAuthenticated ? <DashboardPage handleLogout={handleLogout} /> : <LoginPage />} 
                    />
                    <Route 
                        path="HPPPlandeParis/dashboardDetail/:campaign_id/:campaign_name" 
                        element={isAuthenticated ? <DashboardDetailPage /> : <Navigate to="/login" />} 
                    />

                    {/* Login Route */}
                    <Route 
                        path="/login" 
                        element={<LoginPage />
                        } 
                    />
                </Routes>
            </div>
        </Router>
    );
};

export default App;