import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, NavLink, NavItem, Nav, Table,Button } from "reactstrap";
import eventImage from "./images/logo-dior_mobile_share.jpg"; // Adjust the path as necessary


const RegisterPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [qrCodeUrl, setQrCodeUrl] = useState(''); // URL สำหรับ QR code
    const [secretKey, setSecretKey] = useState(''); // Secret Key สำหรับผู้ใช้
    const navigate = useNavigate();

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            alert('Passwords do not match!');
            return;
        }
    
        try {
            const token = localStorage.getItem('token'); // ดึง JWT token จาก localStorage
    
            // เรียกใช้ API โดยส่ง JWT token ไปใน headers
            const response = await axios.post(`${apiUrl}/api/register`, 
                {
                    username,
                    password,
                    email,
                }, 
                {
                    headers: {
                        'Authorization': `Bearer ${token}` // ส่ง JWT token ใน header
                    },
                        crossDomain: true,   // เพิ่ม crossDomain: true
                }
            );
    
            const result = response.data;
            if (result.success) {
                setQrCodeUrl(result.qrCodeUrl); // เก็บ URL ของ QR code เพื่อแสดงให้ผู้ใช้
                setSecretKey(result.secretKey); // เก็บ Secret Key เพื่อแสดงให้ผู้ใช้
                alert('Registration successful! Scan the QR code with your 2FA app or use the secret key.');
            } else {
                alert(result.message || 'Registration failed!');
            }
        } catch (error) {
            console.error('Error during registration:', error);
            alert('There was an issue with registration. Please try again later.');
        }
    };
    const backClick = () => {
        navigate('/HPPPlandeParis/dashboard');  // Redirect to the login page after logout
    };

    return (
    <div>
        <Container fluid>
          <Row>
          <Col xs="12" md="2" style={{ padding: "0" , background:"black",minHeight: "95vh",height:"857px" }}>
          <div className="text-center">
                <img
                  src={eventImage}
                  alt="Event"
                  style={{ width: "100%", height: "auto", maxWidth: "700px" }}
                />
              </div>
              <Nav vertical className="p-3">
                <NavItem>
                  <NavLink href="/HPPPlandeParis/dashboard" className="text-white">
                    Back
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
        <div style={{ maxWidth: '300px', margin: '0 auto', padding: '20px', textAlign: 'center',top: '2%',left: '50%',position: 'fixed' }}>
            <h2>Register</h2>
            {!qrCodeUrl && !secretKey && (
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={username}
                    onChange={handleUsernameChange}
                    placeholder="Enter your username"
                    style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                />
                <input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Enter your password"
                    style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                />
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    placeholder="Confirm your password"
                    style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                />
                <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Enter your email"
                    style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                />
                <button
                    type="submit"
                    style={{ width: '100%', padding: '10px', borderRadius: '4px', border: 'none', backgroundColor: '#007bff', color: '#fff' }}
                >
                    Register
                </button>
            </form>
            )}
            {qrCodeUrl && (
                <div>
                    <h3>Scan the QR Code with your 2FA app:</h3>
                    <img src={qrCodeUrl} alt="QR Code for 2FA" />
                </div>
            )}
            {secretKey && (
                <div>
                    <h3>Or use this secret key in your 2FA app:</h3>
                    <p>{secretKey}</p>
                </div>
            )}
            <Button style={{ width: '100%'}} onClick={backClick} className="mt-2">
                Back
            </Button>

            
        </div>
        </Row>
        </Container>
    </div>
    );
};

export default RegisterPage;