import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Container,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./RegistrationForm.css";
import eventImage from "./images/eventImage.png"; // Adjust the path as necessary
import { useNavigate } from "react-router-dom";

const CancelCompletePage = () => {
  const navigate = useNavigate();

  const handleUpdate = () => {
    navigate(`/HPPPlandeParis/registration`);
  };

  return (
    <div>
      <div className="text-center">
        <img
          src={eventImage}
          alt="Event"
          // className="img-fluid mb-4"
          style={{ width: "100%", height: "auto", maxWidth: "700px" }}
        />
      </div>
    <Container className="mt-5" style={{ textAlign: "center" }}>
      คุณได้ยกเลิกกิจกรรม
      <br />
      Plan de Paris Event ที่ Hall of Fame, Siam Paragon
      <br />
      สำเร็จ
      <br />
      <br />
      <Button 
      onClick={handleUpdate}
        style={{
          width: "100%",
          maxWidth: "235px",
          backgroundColor: "#000",
          borderColor: "#000",
          color: "#FFF",
        borderRadius: "1px"
      }}
      > 
        ลงทะเบียน Register
      </Button>
    </Container>
    </div>
  );
};

export default CancelCompletePage;
