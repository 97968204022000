import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./RegistrationForm.css";
import diorLogoImg from "./images/dior_logo_ex.png"; // Adjust the path as necessary
import diorBrushImg from "./images/dior_brush.jpg"; // Adjust the path as necessary
import { useLocation } from "react-router-dom";
import imgPUSH02 from "./images/PUSH02.jpg"; // Adjust the path as necessary
import imgPUSH03 from "./images/PUSH03.jpg"; // Adjust the path as necessary


const ConfirmPage = () => {
  const location = useLocation();
  const { value } = location.state || {};
  const [date, setDate] = React.useState("");
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const value = queryParams.get("value");
  // const dateStr = "2024-08-30";
  // useEffect(() => {
  //   console.log(value)
  //   if(value){
  //     const [year, month, day] = value.split('-');
  //     const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  //     const formattedDate = `${day} ${months[parseInt(month) - 1]} ${year}`;
  //     setDate(formattedDate);
  //   }
  

  // }, [value]);
  useEffect(() => {
    // console.log(value);
    if (value) {
      const [year, month, day] = value.split('-');
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      
      // If day is less than 10, remove the leading zero
      const dayFormatted = parseInt(day, 10);
      
      const formattedDate = `${dayFormatted} ${months[parseInt(month) - 1]} ${year}`;
      setDate(formattedDate);
    }
  }, [value]);

  return (
    <div>
    <div className="container my-5">
      <div className="text-center">
        <img
          src={diorLogoImg}
          alt="Event"
          className="img-fluid mb-4"
          style={{ width: "100%", height: "auto", maxWidth: "max-content" }}
        />
      </div>
      <p style={{ textAlign: "center" }}>
        ขอบคุณสำหรับการเข้าร่วมกิจกรรม Plan de Paris Event ที่ Hall of Fame สยาม
        พารากอน
        <br />
        Thank you for participating in Plan de Paris Event at Hall of Fame, Siam
        Paragon
      </p>
      <br />
      <p style={{ textAlign: "center", fontWeight: "bold" }}>วันที่ {date}</p>
      <br />
      <p style={{ textAlign: "center" }}>
        รอรับข้อความ SMS
        และแสดงข้อความที่ได้รับที่จุดลงทะเบียนเพื่อรับสิทธิพิเศษภายในงาน <br />
        Wait for SMS message and show the received message at registration to
        get special privileges at the Event
      </p>
      
    </div>

    <div style={{display: "flex", maxWidth: "100%",justifyContent: "center"}}>
    <div style={{maxWidth: "325px"}}>
        <img
          src={imgPUSH03}
          alt="Event"
          // className="img-fluid mb-4"
          style={{ width: "100%", height: "auto", maxWidth: "none" }}
        />
      </div>
      <div style={{maxWidth: "325px"}}>
        <img
          src={imgPUSH02}
          alt="Event"
          // className="img-fluid mb-4"
          style={{ width: "100%", height: "auto", maxWidth: "none" }}
        />
      </div>
    </div>
    </div>
  );
};

export default ConfirmPage;
